var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12", attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v(" Details Area Manager " + _vm._s(_vm.costCenter) + " "),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Area", readonly: "" },
                        model: {
                          value: _vm.costCenter,
                          callback: function ($$v) {
                            _vm.costCenter = $$v
                          },
                          expression: "costCenter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Name", readonly: "" },
                        model: {
                          value: _vm.fullName,
                          callback: function ($$v) {
                            _vm.fullName = $$v
                          },
                          expression: "fullName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("pga-entity-status", {
                        attrs: { title: "Status" },
                        model: {
                          value: _vm.status,
                          callback: function ($$v) {
                            _vm.status = $$v
                          },
                          expression: "status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }