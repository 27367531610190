var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12" },
    [
      _c("v-card-title", [
        _vm._v(" Create Area Manager " + _vm._s(_vm.costCenter) + " "),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-text-field", {
                        attrs: {
                          label: "Area",
                          "key-error": "CostCenter",
                          errors: _vm.Errors,
                        },
                        model: {
                          value: _vm.costCenter,
                          callback: function ($$v) {
                            _vm.costCenter = $$v
                          },
                          expression: "costCenter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c("pga-select", {
                        attrs: {
                          "item-value": "Id",
                          "item-text": "FullName",
                          items: _vm.userDropDown,
                          label: "Area Manager",
                          "key-error": "UserId",
                          errors: _vm.Errors,
                        },
                        model: {
                          value: _vm.userId,
                          callback: function ($$v) {
                            _vm.userId = $$v
                          },
                          expression: "userId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }